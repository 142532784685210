import { apiBaseUrl } from '../utils/env';

const vake_api_server_url = apiBaseUrl();

export const apiLogin = async (username: string, password: string, callback: Function) => {
    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    const res = await fetch(`${vake_api_server_url}/token`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
    });

    if (!res.ok) {
        callback(null);
    }

    if (res.status === 200) {
        const content = await res.json();
        callback(content.access_token);
    }
};
